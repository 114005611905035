import React, { useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import TagManager from 'react-gtm-module'
import moment from 'moment'

// assets
import bannerFacebook from '../images/banners/banner-padrao-2.png'
import Ilojas from '../images/home/header/icon-lojas.svg'
import IlojasDM from '../images/home/header/icon-lojas-dm.svg'
// Components
import MenuDesktop from '../components/Ofertas/Menu'
import Oferta from '../components/Ofertas/oferta'
import SectionReceitas from '../components/receitas/SectionReceitasPosts/sectionReceitasPosts'

// icones
import whats from '../images/ofertassite/icons/WhatsApp.svg'
import facebook from '../images/ofertassite/icons/Facebook.svg'
import messenger from '../images/ofertassite/icons/Messenger.svg'
import twitter from '../images/ofertassite/icons/Twitter.svg'
import instagram from '../images/ofertassite/icons/insta.svg'

// scss
import Layout from '../components/Layout/index'
import Packery from 'react-packery'
import './ofertasSite.scss'

const OfertasDesktop = (data) => {
  const MasonryOptions = {
    percentPosition: true,
    originLeft: true
  }

  // const carregaImagens = () => {
  //   document.getElementsByClassName('tamanho-grid')[0].style.display = 'block'
  // }

  return (
    <>
      {
        <section className={'my-lg-2 p-0'}>
          <section className="container pb-lg-2 d-lg-flex d-none justify-content-between align-items-center">
            <div className="d-flex w-100 justify-content-between mt-2">
              <h3>Ofertas válidas até <span className="text-red">{moment.utc(data.finishDate).tz('America/Sao_Paulo').format('DD/MM/YYYY')}</span> ou até durarem os estoques, somente em lojas físicas.</h3>
              <div className=''>
                <img src={Ilojas} alt="pin de localização da loja atual" className="mr-1 mb-2 lightMode" width="37" />
                <img src={IlojasDM} alt="pin de localização da loja atual" className="mr-1 mb-2 darkMode" width="37" />
                <b className='text-dark ml-3 mt-1 font-Yanone font-size-12'>
                  {data.loja.name} &nbsp;&nbsp;|
                  <Link to={'/lojas/'} className="text-dark font-openSans w-100 mb-2  font-size-12">
                    <u className="ml-2">ver em outra loja</u>
                  </Link>
                </b>
              </div>
            </div>
          </section>
          <section className="container pb-lg-2 d-lg-none d-block justify-content-between align-items-center">
            <div className="d-block w-100 justify-content-between mt-2">
              <div className='mt-2'>
                <img src={Ilojas} alt="pin de localização da loja atual" className="lightMode mb-2" width="27" />
                <img src={IlojasDM} alt="pin de localização da loja atual" className="darkMode mb-2" width="27" />
                <b className='text-dark ml-3 mt-1 font-Yanone font-size-12'>
                  {data.loja.name} &nbsp;&nbsp;|
                  <Link to={'/lojas/'} className="text-dark font-openSans w-100 mb-2  font-size-12">
                    <u className="ml-2">ver em outra loja</u>
                  </Link>
                </b>
              </div>
              <h3 className="pt-1">Ofertas válidas até <span className="text-red">{moment(data.finishDate).format('DD/MM/YYYY')}</span> ou até durarem os estoques, somente em lojas físicas.</h3>
            </div>
          </section>
          {/* <header className="container pb-lg-3 d-lg-flex d-block justify-content-between align-items-center">
            <h1 className="font-size-2 text-center text-lg-left">
              Ofertas válidas até <span className="text-red">{moment(data.finishDate).format('DD/MM/YYYY')}</span> ou até durarem os estoques
            </h1>
            <div className="d-lg-flex d-inline-block align-items-center">
              <div className="d-flex justify-content-end pr-3 font-Yanone font-size-12">
                <img src={Ilojas} className="mr-2" width='40px' />
                <b className="align-self-center">{data.loja.name}</b>
                <Link to={'/lojas/'} className="text-dark ml-3 mt-1 font-Yanone font-size-12 d-lg-none d-block">
                  <u className="font-size-weight">ver em outra loja</u>
                </Link>
              </div>
              <Link to={'/lojas/'} className="text-dark font-Yanone font-size-12 d-lg-block d-none">
                <u className="font-size-weight">ver em outra loja</u>
              </Link>
            </div>
          </header> */}
          {
            data.tabloide && data.tabloide.length === 0 && (<div className="d-block mx-auto pt-2 bg-dia-red" style={{ position: 'relative' }}>
              <p className="text-center h5">NENHUMA OFERTA ENCONTRADA NESTA CATEGORIA!</p>
            </div>
            )
          }

          <div className="">
            <Packery
              className="tamanho-grid justify-content-center"
              // onImagesLoaded={carregaImagens}
              options={MasonryOptions}>
              <div className="grid-sizer"></div>
              {(data.tabloide && data.tabloide.length > 0) && data.tabloide.map(oferta => {
                return (
                  <div style={{ backgroundColor: 'white' }} key={oferta.id}>
                    <Oferta
                      oferta={oferta}
                      handleClick={data.handleFavorito}
                      idTarifa={data.loja.tariff.id}
                    />
                  </div>
                )
              })}
            </Packery>
          </div>
        </section>
      }
    </>
  )
}

export default function OfertasSite ({ data, pageContext }) {
  const carregaOfertas = () => {
    if (typeof window !== 'undefined') {
      if (!window.localStorage) {
        setLocalStorage()
      }
      const calculaBaseTamanho = () => {
        const base = (window.outerWidth > 1250) ? 1250 : window.outerWidth
        const columns = Math.floor(base / 250) === 1 ? 2 : Math.floor(base / 250)
        return base / columns
      }
      if (window.localStorage) {
        const favoritos = JSON.parse(window.localStorage.getItem('favoritos') || '[]')
        const baseTamanho = calculaBaseTamanho()

        return data.tabloide && data.tabloide.offer.map(o => Object.assign(o, {
          like: favoritos.findIndex(f => f === o.id) > -1,
          style: {
            width: [2, 3].includes(o.format) ? baseTamanho * 2 : baseTamanho,
            height: [1, 3].includes(o.format) ? baseTamanho * 2 : baseTamanho
          }
        }))
      }
    }
  }
  // eslint-disable-next-line no-unused-vars
  const [categoria, setCategoria] = useState(carregaCategorias())
  const [tabloide, setTabloide] = useState(carregaOfertas())
  useEffect(() => {
    // data.tabloide && setTabloide()
    const { loja } = data
    window.localStorage.setItem('OFERTAS_SEMANA', loja.slug)
    let TagManagerArgs
    if (loja.tariff !== null) {
      TagManagerArgs = {
        gtmId: 'GTM-TF6VN34',
        lojaLayer: {
          lojaNumero: loja.storeNumber,
          lojaTarifa: loja.tariff.id,
          // lojaTipo: loja.Tipo,
          lojaEndereco: loja.address,
          lojaCidade: loja.city,
          lojaEstado: loja.uf,
          interface: 'site'
        }
      }
    } else {
      TagManagerArgs = {
        gtmId: 'GTM-TF6VN34',
        dataLayer: {
          lojaNumero: loja.storeNumber,
          // lojaTipo: loja.Tipo,
          lojaEndereco: loja.address,
          lojaCidade: loja.city,
          lojaEstado: loja.uf,
          interface: 'site'
        }
      }
    }

    TagManager.dataLayer(TagManagerArgs)
  }, [])

  function carregaCategorias () {
    return data.categorias.nodes.map(o => {
      const ofertasCat = separeTabloideToCategory(o)
      if (ofertasCat) {
        o.exibe = !!(ofertasCat.length > 0 || (o.type === 'FAVORITOS' || o.type === 'TODOS'))
      }
      return o
    })
  }

  function filtroOfertas (cat) {
    setTabloide(separeTabloideToCategory(cat))
  }

  function separeTabloideToCategory (filter) {
    let banner
    let filterOfertas
    let ofertas
    if (data.tabloide) {
      switch (filter.type) {
        case 'TODOS': {
          ofertas = data.tabloide.offer
          filterOfertas = ofertas.filter((item) => item.category?.type !== 'BANNER')
          banner = ofertas.filter(item => item.category?.type === 'BANNER')
          return [...banner, ...filterOfertas]
        }
        case 'NORMAL':
          ofertas = data.tabloide.offer.filter(item => (item.category?.id === filter.id))
          filterOfertas = ofertas.filter((item) => item.category?.type !== 'BANNER')
          banner = ofertas.filter(item => item.category?.type === 'BANNER')
          return [...banner, ...filterOfertas]
        case 'TAG': {
          ofertas = data.tabloide.offer
          const tags = filter.tag.split(',')
          return ofertas.filter(item => item.tag && item.tag.includes(tags.map((tag) => { return tag })))
        }
        case 'MARCA':
          ofertas = data.tabloide.offer.filter(item => item.brand && (item.brand.id.includes(filter.brand.map((brandF) => { return brandF.id }))))
          filterOfertas = ofertas.filter((item) => item.category?.type !== 'BANNER')
          banner = ofertas.filter(item => item.category?.type === 'BANNER')
          return [...banner, ...filterOfertas]
        case 'FAVORITOS': {
          ofertas = data.tabloide.offer
          return ofertas.filter(item => (item.like))
        }
        default: {
          return data.tabloide.offer.filter(item => (item.category?.id === filter.id))
        }
      }
    }
  }

  const setLocalStorage = () => {
    Object.defineProperty(window, 'localStorage', new function () {
      var aKeys = []; var oStorage = {}
      Object.defineProperty(oStorage, 'getItem', {
        value: function (sKey) { return sKey ? this[sKey] : null },
        writable: false,
        configurable: false,
        enumerable: false
      })
      Object.defineProperty(oStorage, 'key', {
        value: function (nKeyId) { return aKeys[nKeyId] },
        writable: false,
        configurable: false,
        enumerable: false
      })
      Object.defineProperty(oStorage, 'setItem', {
        value: function (sKey, sValue) {
          if (!sKey) { return }
          document.cookie = escape(sKey) + '=' + escape(sValue) + '; expires=Tue, 19 Jan 2038 03:14:07 GMT; path=/'
        },
        writable: false,
        configurable: false,
        enumerable: false
      })
      Object.defineProperty(oStorage, 'length', {
        get: function () { return aKeys.length },
        configurable: false,
        enumerable: false
      })
      Object.defineProperty(oStorage, 'removeItem', {
        value: function (sKey) {
          if (!sKey) { return }
          document.cookie = escape(sKey) + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/'
        },
        writable: false,
        configurable: false,
        enumerable: false
      })
      Object.defineProperty(oStorage, 'clear', {
        value: function () {
          if (!aKeys.length) { return }
          for (var sKey in aKeys) {
            document.cookie = escape(sKey) + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/'
          }
        },
        writable: false,
        configurable: false,
        enumerable: false
      })
      this.get = function () {
        var iThisIndx
        for (var sKey in oStorage) {
          iThisIndx = aKeys.indexOf(sKey)
          if (iThisIndx === -1) { oStorage.setItem(sKey, oStorage[sKey]) } else { aKeys.splice(iThisIndx, 1) }
          delete oStorage[sKey]
        }
        for (aKeys; aKeys.length > 0; aKeys.splice(0, 1)) { oStorage.removeItem(aKeys[0]) }
        for (var aCouple, iKey, nIdx = 0, aCouples = document.cookie.split(/\s*;\s*/); nIdx < aCouples.length; nIdx++) {
          aCouple = aCouples[nIdx].split(/\s*=\s*/)
          if (aCouple.length > 1) {
            oStorage[iKey = unescape(aCouple[0])] = unescape(aCouple[1])
            aKeys.push(iKey)
          }
        }
        return oStorage
      }
      this.configurable = false
      this.enumerable = true
    }())
  }

  const notificaFavorito = (oferta) => {
    if (!window.localStorage) {
      setLocalStorage()
    }
    tabloide.filter(offer => offer.id === oferta.id && (offer.like = oferta.like))
    const favoritos = tabloide.filter(t => (t.like)).map(o => (o.id))
    if (window.localStorage) {
      window.localStorage.setItem('favoritos', JSON.stringify(favoritos))
    }
  }

  const breadC = {
    visible: true,
    color: 'black', // Options black || white
    items: [
      { path: '/', name: 'Home' },
      { path: '/ofertas/', name: 'Ofertas da Semana' }
    ]
  }

  function createDescritpion (services) {
    var description
    var Ifood = false
    var Rappi = false

    services.map((serv) => {
      if (serv.name === 'Ifood') {
        Ifood = true
      } else if (serv.name === 'Rappi') {
        Rappi = true
      }
    })

    if (Ifood || Rappi) {
      if (Rappi) {
        description = 'Acesse e confira as ofertas desta semana no DIA Supermercado.'
      }
      if (Ifood) {
        description = 'Acesse e confira as ofertas desta semana no DIA Supermercado.'
      }
      if (Ifood && Rappi) {
        description = 'Acesse e confira as ofertas desta semana no DIA Supermercado.'
      }
    } else {
      description = `Ofertas válidas até: ${moment(data.finishDate).format('DD/MM/YYYY')} ou até durarem os estoques, somente em lojas físicas.`
    }
    return description
  }

  const pageUrl = `https://www.dia.com.br/lojas/${data.loja.slug}`

  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="UTF-8" />
        <title> Loja: {data.loja.uf} - {data.loja.city} - {data.loja.district} - {data.loja.address}</title>
        <meta name="description" content={createDescritpion(data.loja.services)} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="DIA Supermercados" />
        <meta property="og:title" content={`Confira as ofertas da Loja: ${data.loja.uf} - ${data.loja.city} - ${data.loja.district} - ${data.loja.address}`} />
        <meta property="og:description" content={createDescritpion(data.loja.services)} />
        <meta property="og:type" content="article" />
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:image" content={`${data.site.siteMetadata.siteUrl}${bannerFacebook}`} />
        <meta property="og:site_name" content={data.site.siteMetadata.siteName} />
        <meta property="og:url" content={data.site.siteMetadata.siteUrl + '/lojas/' + data.loja.slug + '/'} />
        <meta property="article:author" content="DIA Supermercados" />
        <meta property="fb:app_id" content={data.site.siteMetadata.facebookAppId} />
        <script type='application/ld+json'>
          {`
            {
              "@context": "http://schema.org",
              "@type": "Store",
              "name": "Loja: $${data.loja.uf} - ${data.loja.city} - ${data.loja.district} - ${data.loja.address}",
              "url": "${data.site.siteMetadata.siteUrl}/lojas/${data.loja.slug}/",
              "image": "${data.site.siteMetadata.siteUrl}/card-dia.png",
              "description": "${createDescritpion(data.loja.services)}",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "${data.loja.address}",
                "addressLocality": "${data.loja.city}",
                "addressRegion": "${data.loja.state}",
                "postalCode": "${data.loja.cep}",
                "addressCountry": "Brasil"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": "${data.loja.lat}",
                "longitude": "${data.loja.lng}"
              },
              "openingHoursSpecification": [
                {
                  "@type": "OpeningHoursSpecification",
                  "dayOfWeek": [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday"
                  ],
                  "opens": "${data.loja.mondayOpen}",
                  "closes": "${data.loja.mondayClose}"
                },
                {
                  "@type": "OpeningHoursSpecification",
                  "dayOfWeek": "Sunday",
                  "opens": "${data.loja.sundayOpen}",
                  "closes": "${data.loja.sundayClose}"
                }
              ]
            }
          `}
        </script>
      </Helmet>
      <script
        dangerouslySetInnerHTML={{
          __html: `
        fbAsyncInit = function() {
          FB.init({
            appId      : '${data.site.siteMetadata.facebookAppId}',
            cookie     : true,
            xfbml      : true,
              version    : '${data.site.siteMetadata.facebookAPIVersion}'            
            });
            FB.AppEvents.logPageView();
          };
          (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/pt_BR/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));
          `
        }}
      />
      <MenuDesktop handleFilter={filtroOfertas} loja={data.loja} app={false} categorias={categoria} />
      {
        !data.tabloide
          ? (<div className="d-block mx-auto pt-2 pb-3" style={{ position: 'relative' }}>
            <h1 className="text-center h5">NENHUMA OFERTA ENCONTRADA NESTA LOJA!</h1>
          </div>)
          : (
            <>
              <OfertasDesktop tabloide={tabloide} loja={data.loja} handleFavorito={notificaFavorito} finishDate={data.tabloide.finishDate} />
              <section className='d-flex text-center justify-content-center pt-5'>
                <div className='px-4 shareSection'>
                  <div className='d-lg-flex d-block justify-content-center align-items-center sharetext'>
                    <p className='m-0 p-0'><span className='font-size-20px text-black'>Compartilhe as ofertas com os seus amigos:</span> </p>
                    <a href={`https://api.whatsapp.com/send?text=${pageUrl}`} target="_blank" without rel="noreferrer">
                      <img src={whats} height='30px' width='30px' className='ml-2 mb-lg-0 mb-1' alt='whatsapp' />
                    </a>
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(pageUrl)}`} target="_blank" without rel="noreferrer">
                      <img src={facebook} height='30px' width='30px' className='ml-2 mb-lg-0 mb-1' alt='facebook' />
                    </a>
                    <a href={`https://www.facebook.com/dialog/send?app_id=${data.site.siteMetadata.facebookAppId}&link=${pageUrl}&redirect_uri=${pageUrl}`} target="_blank" without rel="noreferrer">
                      <img src={messenger} height='30px' width='30px' className='ml-2 mb-lg-0 mb-1' alt='messenger' />
                    </a>
                    <a href={`https://twitter.com/intent/tweet?url=${pageUrl}`} target="_blank" without rel="noreferrer">
                      <img src={twitter} height='30px' width='30px' className='ml-2 mb-lg-0 mb-1' alt='twitter' />
                    </a>
                    {/* <a href={''} target="_blank" without rel="noreferrer">
                      <img src={instagram} height='30px' width='30px' className='ml-2 mb-lg-0 mb-1' alt='instagram' />
                    </a> */}
                    {/* MUDAR OOOOOOOOO EVIIIIIIIIIIIIIIIIIIII */}
                    {/* GATSBY CONGIF  E ENVVVVVV: */}
                  </div>
                </div>
              </section>
              <p className="m-5">{data.tabloide.legalText}</p>
            </>
          )
      }

      {/* <SectionReceitas /> */}
    </Layout>
  )
}

export const query = graphql`
query ($idTarifa: String!, $ID: String!) {
  loja: cloudOfertasLoja(id: {eq: $ID}) {
    id
    slug
    address
    cep
    city
    district
    lat
    lng
    name
    storeNumber
    tariff {
      id
      name
    }
    reference
    status
    uf
    whatsapp
    mondayOpen
    mondayClose
    holidayOpen
    holidayClose
    sundayOpen
    sundayClose
    services {
      icon
      name
    }
  }
  categorias: allCloudOfertasCategoria(sort: {fields: sequence}) {
    nodes {
      name
      id
      sequence
      type
      brand {
        name
        id
      }
      tag
      icon {
        url
      }
    }
  }
  tabloide: cloudOfertasTabloide(tariff: {id: {eq: $idTarifa}}){
      offer {
        linkBanner
        tag
        id
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
        format
        finishDate
        order
        category {
          type
          sequence
          id
          name
        }
        brand {
          name
          id
        }
      }
      legalText
      finishDate
      startDate
      tariff {
        id
        name
      }
  }
  site:site {
    siteMetadata {
      siteUrl
      facebookAppId
      author
      title
      siteName
      tagManagerId
      facebookAPIVersion
    }
  }
}`
